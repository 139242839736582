/**
 * MASTER JS BRICOSK 2021
 */

const breakpoints = {
    s:  679,
    m:  959,
    l:  1199,
    xl: 1599
};



// on load
$(function() {
    disabled()
    if(getCookie('newsletter') === null) {
      setCookie('newsletter', 'false', 1);
    }

    if(getCookie('gdpr') === null) {
      setCookie('gdpr', 'false', 1);
    }


    // run menu management
    gdpr()
    //menu()
    menuLight()
    headerSpy()
    // run carousel management
    carousel(8000)
    fileLoader()
    // run modal management
    modal('#modal__newsletter','modal__newsletter', '.trigger__newsletter', 2000, getCookie('newsletter') == 'false')
    modal('#modal__login', 'modal__login', '.trigger__login', -1)
    loginModalAction();
    flash();
    passwordDiscover();

    registerStep();
    loginTab();
    droptions()

    callDataTable()
    search()
})

const search = () => {
    $('#search').on('keyup', function() {
        var needle = $(this).val()
        var searchResult = $('.search__result')

        if(needle != '' || needle.length > 3) {
            $.ajax({
                method: "POST",
                url: "/api/search",
                data: {search: needle},
                success: function(res) {

                    if(res.length > 0){
                        searchResult.addClass('open')
                        searchResult.html(null)

                        res.map(function(item, idx) {
                            if(idx < 6) {
                                var template = `<a href="${item.tag_slug}/${item.slug}" class="result">
                            <img src="/assets/images/${item.miniature}" width="50" alt="${item.title}">
                            <span>${item.title}</span>
                        </a>`;
                                searchResult.append(template)
                            }
                        })

                        if (res.length > 6) {
                            searchResult.append(`<a href="/recherche?search=${needle}" class="result py2">Plus de résulats trouvés (${res.length})</a>`)
                        }
                    }

                }
            })
        } else {
            searchResult.html(null)
        }

    })

}
const callDataTable = _ => {
    $('#quoteDataTable').DataTable({
        responsive: true,
        ordering: true,
        paging: false,
        searching: true,
        select: false,
        info: false
    })
}
const droptions = _ => {
    $('.trigger-droption').on('click', function(e) {
        $(this).siblings('.options').toggleClass('open')
        $(this).children('.fa').toggleClass('fa-ellipsis-h').toggleClass('fa-times')
    })
}
const gdpr = _ => {
    if(getCookie('gdpr') == 'false') {
        $('#modal__gdpr').css('display', 'block')
    }

    // When the user clicks on <span> (x), close the modal
    $('.validate').on('click', function() {
        $('#modal__gdpr').css('display', 'none')
        setCookie('gdpr', 'true', 31)
    })
}
function tagManager () {
    if(getCookie('gdpr') == 'true') {

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-TMSH7S1SX0');

    }
}
const menu = _ => {
    $('.nav-box__action').on('click', function(e) {
        e.preventDefault()
        
        let hasClass = $(this).siblings('.nav-box__mega').hasClass('open');
        
        $('.nav-box__mega').removeClass('open')
        
        if(hasClass) {
            $(this).siblings('.nav-box__mega').removeClass('open');
        } else {
            $(this).siblings('.nav-box__mega').addClass('open');
        } 
    })

    // aside active
    $('.mega__aside--link').on('click', function(e) {
        e.preventDefault();
        
        let id = $(this).attr('href');
        
        $('.mega__content').removeClass('open');
        $(id).addClass('open');

        $('.mega__aside--link').removeClass('open')
        $(this).toggleClass('open')
        
    })

    // scroll spy to fixe the menu on top
    $(document).on('scroll', function () {
        
        if ($('.header__main-box').isInViewport().length == 0) {
            if(!$('.header__nav-box').hasClass('nav-box__fixed')) {
                $('.header__nav-box').addClass('nav-box__fixed')
                $('#pre-header').addClass('pre-header__fixed')
            }
        } else {
            $('.header__nav-box').removeClass('nav-box__fixed')
            $('#pre-header').removeClass('pre-header__fixed')
        
        }
    });

}
const menuLight = () => {

    $(document).on('click', '.nav-box__action', function(e) {
        e.preventDefault()
        $(this).next('.nav-box__dropdown').toggleClass('open');
    })

    // mobile menu
    $(document).on('click', '.trigger_menu', function(e) {
        e.preventDefault()
        let target = $(this).attr('href')
        $(target).toggleClass('open')
    })

    $(document).on('click', '.header__link--dropdown', function(e) {
        e.preventDefault()
        $(this).focus()
        let target = $(this).attr('href')
        $(target).toggleClass('open')
    })

    $(document).on('focusout', '.header__link--dropdown', function(e) {
        setTimeout(_ => {
            let target = $(this).attr('href')
            $(target).removeClass('open');
        }, 200)
    });



}
const headerSpy = () => {
    // scroll spy to fixe the menu on top
    $(document).on('scroll', function () {
        
        if ($('.header__main-box').isInViewport().length == 0) {
            if(!$('.header__nav-box').hasClass('nav-box__fixed')) {
                $('.header__nav-box').addClass('nav-box__fixed')
                $('#pre-header').addClass('pre-header__fixed')
            }
        } else {
            $('.header__nav-box').removeClass('nav-box__fixed')
            $('#pre-header').removeClass('pre-header__fixed')
        
        }
    });
}
const carousel = (delay) => {
    $('.carousel__links--link').on('click', function(e){
        e.preventDefault()

        let id = $(this).attr('href');

        $('.carousel__links--link').removeClass('open')
        $(this).addClass('open')

        $('.carousel__contents--content').removeClass('open');
        $(id).addClass('open');
    })

    $('.carousel__contents').on('mouseover', function(e) {
        clearInterval(carousel)
    });

    var interval;

    let carousel = () => {
        interval = setInterval(function() {
            var active = $('.carousel__links--link.open')

            if(!active.is(':last-child')) {
                active.removeClass('open').next().addClass('open')
                let id = active.next().attr('href')

                $('.carousel__contents--content').removeClass('open');
                $(id).addClass('open');
            } else {
                $('.carousel__links--link').removeClass('open')
                $('.carousel__links--link:first-child').addClass('open')
                $('.carousel__contents--content').removeClass('open');
                let id = $('.carousel__links--link:first-child').attr('href');
                $(id).addClass('open');
            }
        }, delay)
    }

    carousel()

    $('.carousel__contents').hover(
        function () {
            clearInterval(interval)
        },
        function () {
            carousel();
        }
    )



    // clearInterval(carousel)
}
const modal = (modalId, id, trigger, delay, test) => {
    var name = modalId.slice(1,-1)

    // Get the modal
    var $modal = $(modalId)
    var modal = document.getElementById(id);
    var backdrop = $modal.next('.backdrop');

    // Get the button that opens the modal
    var btn = $(trigger)

    // Get the <span> element that closes the modal
    var span = $(".close")

    // When the user clicks on the button, open the modal
    $(window).on('load', function() {

        if(delay !== -1 && test) {
            setTimeout(function() {
                $modal.addClass('open')
                setCookie('newsletter', 'true', 31)
            }, delay)
        }
 
    })
    

    // When the user clicks on <span> (x), close the modal
    span.on('click', function() {
        $modal.removeClass('open')
        $('body').css('overflow', 'unset');
    })

    backdrop.on('click', function() {
        $modal.removeClass('open')
        $('body').css('overflow', 'unset');
    })

    btn.on('click', function() {
        $modal.addClass('open')
        $('body').css('overflow', 'hidden');
    })


    // $($modal).on('click', function() {
    //     if(!$(this).hasClass('modal-content')) {
    //         $(this).css('display', 'none');

    //     }

    // })
}
function loginModalAction () {
    $('.auth--action').on('click', function(e) {
        e.preventDefault();
        $(this).parents('.tab__content').removeClass('open');
        $($(this).attr('href')).addClass('open');
    })
}
function calculator(moduleName) {

    let
      type = 0.00,
      longueur = 0.00,
      largeur = 0.00,
      epaisseur = 0.00,
      surface = 0.00,
      volume = 0.00,
      sandWeight = 0.00,
      sandBag = 0.00,
      concreteWeight = 0.00,
      concreteBag = 0.00,
      gravelWeight = 0.00,
      gravelBag = 0.00,
      water = 0.00;
    $('#surface').text(surface.toFixed(2))
    $('#volume').text(volume.toFixed(2))
  
    $('#sandWeight').text(sandWeight.toFixed(2))
    $('#sandBag').text(sandBag.toFixed(0))
  
    $('#concreteWeight').text(concreteWeight.toFixed(2))
    $('#concreteBag').text(concreteBag.toFixed(0))
  
    $('#gravelWeight').text(gravelWeight.toFixed(2))
    $('#gravelBag').text(gravelBag.toFixed(0))
  
    $('#water').text(water.toFixed(2))
  
  
    $(document).on('keyup', '.calculator-input', function(e) {
      e.preventDefault()
  
  
      type = $('#calculator').data('module');
      longueur = $("#longueur").val();
      largeur = $("#largeur").val();
      epaisseur = $("#epaisseur").val();
  
      if (Number(longueur) !== 0)
        $('#longueur-value').text(longueur)
      if (Number(largeur) !== 0)
        $('#largeur-value').text(largeur)
      if (Number(epaisseur) !== 0)
        $('#epaisseur-value').text(epaisseur)
  
  
      // calculator surface
      surface = longueur * largeur;
      volume = surface * (epaisseur/100); // M vs CM
  
      if(Number(longueur) !== 0 && Number(largeur) !== 0)
        $('#surface').text(surface.toFixed(2))
  
      if(Number(longueur) !== 0 && Number(largeur) !== 0 && Number(epaisseur) !== 0)
        $('#volume').text(volume.toFixed(2))
  
      // calculator bags
      switch (moduleName) {
        case 'dalleBeton':
          // 0.01m³ = 8k sand / 4 kg ciment / 10 Kg gravier / 2L eau
          sandWeight = volume * 800
          concreteWeight = volume * 400
          gravelWeight = volume * 1000
          water = volume * 200
          break;
        case 'chape':
          // 0.01m³ = 15kg sand / 3kg ciment / 0Kg gravier / 2L eau
          sandWeight = volume * 1500
          concreteWeight = volume * 300
          water = volume * 200
          break;
        case 'stabilise':
          // 0.01m³ = 15kg sand / 2kg ciment / 0Kg gravier / 1L eau
          sandWeight = volume * 1500
          concreteWeight = volume * 200
          water = volume * 100
          break;
      }
  
  
  
      sandBag = (sandWeight % 25 !== 0) ? Math.floor(sandWeight / 25) + 1 : Math.floor(sandWeight / 25)
      concreteBag = (concreteWeight % 25 !== 0) ? Math.floor(concreteWeight / 25) + 1 : Math.floor(concreteWeight / 25)
      gravelBag = (gravelWeight % 25 !== 0) ? Math.floor(gravelWeight / 25) + 1 : Math.floor(gravelWeight / 25)
  
      $('#sandWeight').text(sandWeight?.toFixed(2))
      $('#sandBag').text(sandBag?.toFixed(0))
  
      $('#concreteWeight').text(concreteWeight.toFixed(2))
      $('#concreteBag').text(concreteBag.toFixed(0))
  
      if(moduleName === 'dalleBeton') {
        $('#gravelWeight').text(gravelWeight.toFixed(2))
        $('#gravelBag').text(gravelBag.toFixed(0))
      }
  
      $('#water').text(water.toFixed(0))
  
  
    })
  }
function PrintElem(elem) {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
  
    mywindow.document.write('<html><head><link rel="stylesheet" href="/assets/css/master.min.css"><title>' + document.title  + '</title></head>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    return true;
  }
const flash = _ => {
      $('.alert .close').on('click', function() {
          $(this).parents('.alert').remove();
      })
  }
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return  null;
}
function passwordDiscover () {
    $('.password__display').on('click', function(e) {
        e.preventDefault()
        let input = $(this).prev('input')
        let type = input.attr('type')

        $(this).children('i').toggleClass('fa-eye').toggleClass('fa-eye-slash')

        if (type === 'password') {
            input.attr('type', 'text')
        } else if (type === 'text') {
            input.attr('type', 'password')
        }

    })
}
function registerStep () {

    let step = 1,
        section = $('.step.open'),
        $heading = $('.stepper__item.open'),
        nextButton = $('.step__button--next'),
        nextButtonClass = '.step__button--next',
        prevButton = $('.step__button--prev'),
        prevButtonClass = '.step__button--prev',
        submitButton = $('.step__submit'),
        submitButtonClass = '.step__submit',
        formId = $('#form__register');


    $(document).on('keyup change', '.input__error',  function() {
        $(this).removeClass('input__error')
        $(this).parents('.group__display').next('.helper').remove()
    })

    $(document).on('click', nextButtonClass, function(e) {
        e.preventDefault()

        let validations = {
            1: {
                'reg_first_name': $('#reg_first_name').val(),
                'reg_last_name': $('#reg_last_name').val(),
                'reg_email': $('#reg_email').val(),
                'reg_password': $('#reg_password').val(),
                'reg_confirm_password': $('#reg_confirm_password').val()
            },
            2: {
                'reg_company': $('#reg_company').val(),
                'reg_vat': $('#reg_vat').val(),
            },
            3: {
                'reg_country': $('#reg_country').val(),
                'reg_address': $('#reg_address').val(),
                'reg_address2': $('#reg_address2').val(),
                'reg_city': $('#reg_city').val(),
                'reg_zipcode': $('#reg_zipcode').val(),
                'reg_optin_newsletter': $('#reg_optin_newsletter:checked').val(),
                'reg_optin_data': $('#reg_optin_data:checked').val()
            }
        }


        $.ajax({
            method: "POST",
            url: "/api/register",
            data: {data: validations[step], step: step},
            success: function(res) {
                if(!res.has_error) {

                    step++;

                    // change heading active
                    $heading.next('.stepper__item').addClass('open');
                    $heading.removeClass('open').addClass('done');
                    // update heading pointer
                    $heading = $heading.next('.stepper__item')

                    // change section active
                    section.next('.step').addClass('open');
                    section.removeClass('open');
                    // update section pointer
                    section = section.next('.step')

                    prevButton.addClass('open')

                    if (step === 3) {

                        nextButton.removeClass('open')
                        submitButton.addClass('open')
                    }
                } else {
                    for (const key in res) {
                        let id = '#'+key

                        if(res[key]) {

                            $(id).removeClass('input__success').addClass('input__error');
                            $(id).parents('.group__display').next('.helper').remove()
                            $(id).parents('.group__display').after('<div class="helper error">Veuillez indiquer une valeur correcte</div>');
                        }

                    }
                }
            }
        })
    })


    $(document).on('click', submitButtonClass, function(e) {
        e.preventDefault();
        let step = 3;
        let validations = {
            3: {
                'reg_country': $('#reg_country').val(),
                'reg_address': $('#reg_address').val(),
                'reg_address2': $('#reg_address2').val(),
                'reg_city': $('#reg_city').val(),
                'reg_zipcode': $('#reg_zipcode').val(),
                'reg_optin_newsletter': $('#reg_optin_newsletter:checked').val(),
                'reg_optin_data': $('#reg_optin_data:checked').val()
            }
        }

        $.ajax({
            method: "POST",
            url: "/api/register",
            data: {data: validations[step], step: step},
            success: function(res) {
                if(res.has_error) {
                    for (const key in res) {
                        let id = '#'+key
                        if(res[key]) {
                            $(id).removeClass('input__success').addClass('input__error');
                            $(id).parents('.group__display').next('.helper').remove()
                            $(id).parents('.group__display').after('<div class="helper error">Veuillez indiquer une valeur correcte</div>');
                        }
                    }
                } else {
                    formId.submit()
                }
            }
        })

    })

    $(document).on('click', prevButtonClass, function(e) {

        e.preventDefault()
        step--;
        // change heading active
        $heading.prev('.stepper__item').addClass('open').removeClass('done');
        $heading.removeClass('open').removeClass('done');
        // update heading pointer
        $heading = $heading.prev('.stepper__item')

        // change section active
        section.prev('.step').addClass('open');
        section.removeClass('open');

        // update section pointer
        section = section.prev('.step')

        if (step === 1) {
            nextButton.addClass('open')
            $(this).removeClass('open')
            submitButton.removeClass('open')
        }

        if (step === 2) {
            nextButton.addClass('open')
            prevButton.addClass('open')
            submitButton.removeClass('open')
        }

    })
}
function loginTab () {
    $('.trigger__login--action').on('click', function(e) {
        e.preventDefault()
        let id = $(this).attr('href')
        $('.login__section').removeClass('open')
        $(id).addClass('open')
    })
}
function disabled() {
    $('.btn--disabled').on('click', function(e) {
        e.preventDefault()
    })
}
function quoteTool() {

    $(document).on('click', '#quote__add', function(e) {
        e.preventDefault()

        $.ajax({
            method: "GET",
            url: "/api/units",
            success: function(res) {

                let html_units = res.map(value => {
                    return `<option value="${value.short}">${value.name}</option>`;
                });

                let tr = `<tr class="animate__animated animated--fade-in">
                            <td>
                                <div class="quote__action">
                                    <input type="text" placeholder="Entrez le nom de votre produit" name="q_name[]">
                                </div>
                            </td>
                            <td><input type="text" placeholder="Quantités" name="q_quantity[]"></td>
                            <td>
                                <select name="q_unit[]" id="q_unit">
                                <<option value=""></option>
                                    ${ html_units }
                                </select>            
                            </td>
                            <td>
                                <button class="btn btn--secondary-link quote__delete"><i class="fa fa-minus-circle"></i></button>
                            </td>
                        </tr>`

                $('#quote__table').children('tbody').append(tr);
            }
        })

    })

    $(document).on('click', '.quote__delete', function(e) {
        e.preventDefault()
        $(this).parents('tr').remove()
    })

}
function fileLoader() {
    $('.file--loader').each(function() {
        let data = $(this).data('src')
        let width = $(document).outerWidth()
        let controls = (width <= breakpoints.l ? '#toolbar=0' : '');
        $(this).attr('src', data + controls);
    })
}